// Step 1: Import React
//import * as React from 'react'
import React, { useEffect }  from 'react'
import Layoutp from '../components/Layoutp'
import { StaticImage } from 'gatsby-plugin-image'

// Step 2: Define your component
const AboutPage = () => {
  useEffect(() => {
    console.log('useEffect');
    var element = document.getElementById('home');
    element.style.background = 'none';
    element.style.height = 'auto';
    element.style['min-height'] = '0px';
  },[]);
  return (
    <Layoutp pageTitle="Sakura Hall 2024">
	  <h3 style={{"margin-top": "-60rem", "text-align": "center", "color": "white"}}>『2025.2.28（金）2025守屋純子オーケストラ定期公演・25周年/Tribute to Benny Golson』</h3>
            <h3 style={{ "text-align": "center", "color": "white"}}>HP販売</h3>
            <div className=''>
　　　　　　　    <StaticImage className="main-eye-catch-l" style={{"width":"50%", "margin-right": "25%", "margin-left": "25%" }}
　　　　　　        alt="定期公演"
　　　　　　        src="../images2/2025sakurahall_omote.jpg"
　　　　　　      />
                <p style={{ "text-align": "center", "color": "white" }}><b>2025.2/28(金) 渋谷さくらホール　　open:18:15--  /Start: 19:00--</b><br/> 
               一般4800円、学生3000円</p>
                <div style={{ "text-align": "center", "color": "white", "width": "50%", "margin-right": "auto", "margin-left": "auto" }}>
                <p>
                  お問合せ：0570-00-3337　サンライズプロモーション東京（平日12-15時）<br/>
                  フライヤーは以下からDLできます。<a href="https://jmo.junkomoriya.com/wp-content/uploads/2025sakurahall_omote.jpg">2025.2.28コンサートチラシ表</a>、<a title="チラシ裏" href="https://jmo.junkomoriya.com/wp-content/uploads/2025sakurahall_ura.jpg">チラシ裏</a><br/>
                  e+,チケットぴあ、ローソンチケットでも販売しております。チラシ裏面をごらんください。<br/>（ただし、このHP販売の方が、良いお席を指定できます）
                </p>
                <p>
                </p>
                <p style={{ "text-align": "center", "color": "white", "text-indent": "1em" }}>
	  2024年9月、偉大なサックス奏者・作編曲家であったBenny Golsonが亡くなりました。彼の多くの名曲の中から、新たに今回のためにアレンジした曲をお届けして追悼します。また、2024年はHenry Mancini生誕100周年の年でもあり、彼の曲の新アレンジや、オリジナルの新曲なども演奏します。毎年2月に行なっている定期公演も、今回で25周年。ここでしか聴けない豪華な音にどうぞご期待ください。<br/>
        
                </p>
                <h3 style={{ "color": "white" }}>＜HP販売について＞</h3>
                <p style={{ "text-align": "left" }}>
チケットはチケットぴあ等でもお求めになれますが、<span style={{ "color": "red", "font-weight": "bold" }}>最も良いお席は、このHPにて座席指定で御求めいただけます。学生券もこのHPで販売しております。</span><u>学生券は座席指定はできません。</u>また当日学生証の提示が必要です。
                </p>
                <p style={{ "text-align": "left" }}>
一般席・学生席とも、<a href="https://jmo.junkomoriya.com/?page_id=1100&ref=rp">こちらのフォーム</a>からお申し込みください。（ご希望の＜座席＞を1枚選ぶたびに、追加ボタンを押してください。）このフォームでうまく申し込めない場合は、ご希望のお席と送付先ご住所、支払い方法のご希望を守屋まで直接<a href="mailto:moriyajko@gmail.com">メール</a>してください。
                </p>
                </div>
              </div>
              <hr/>
    </Layoutp>
  )
}

// Step 3: Export your component
export default AboutPage
