import * as React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import Navigation from './navigation'
import {
  container,
  heading,
  navLinks,
  navLinkItem,
  navLinkText,
  siteTitle
} from './layout.module.css'


const Layoutp = ({ pageTitle, children }) => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="app">
      <title>{pageTitle} | {data.site.siteMetadata.title}</title>
      <div data-reactroot className="main-container">
        <Header>
          <Navigation/>
        </Header>      
          {children}
      </div>
    </div>
  )
}

export default Layoutp

